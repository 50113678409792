<template>
	<div class="Commodity">
		<w-navTab titleText="义警商城"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<template v-if="userInfo.merchant_id">
			<div class="header-top" v-if="active == 0 && userInfo.merchant_id.length == 1"
				@click="$router.push({ name: 'CommodityMerchant', params:{id: userInfo.merchant_id[0]}})">我的店铺</div>
			<div class="header-top" v-if="active == 0 && userInfo.merchant_id.length > 1"
				@click="$router.push({ name: 'CommodityMerchantList'})">我的店铺</div>
		</template>
		<div class="header-top-list" v-if="active == 0" @click="$router.push({ name: 'ConsumeList', params: { type: 0 }})">我的订单</div>
		<div class="commodity-top" v-if="active == 0"><img src="../../assets/img/yjsc.jpg" alt="" /></div>
		<div class="container">
			<div :class="active == 0 ? 'container-top' : 'container-top-active'">
				<div class="head-title"><w-tab :data="navList" :active="active" :search="true" @click="onTab"
						@click-search="toSearch"></w-tab></div>
			</div>
			<VantList class="list-view" v-if="active == 0" :loading="loading" :finished="finished" :is-empty="isEmpty"
				@onLoad="onLoad">
				<div class="container-body">
					<div class="item-box" v-for="item in companyList"
						@click="toDetail(item.id)">
						<div class="item-box-picture"><img :src="item.thumb" alt="" /></div>
						<div class="item-box-bottom">
							<div class="item-box-text">{{ item.title }}</div>
							<div class="item-box-price">
								<div class="price-left">
									<div class="price-title">
										<span>￥</span>{{item.price}}
									</div>
									<!-- <div class="price-text">
										￥{{item.price}}{{parseInt(item.price*100 - item.discount*100)/parseInt(100).toFixed(2)}}
									</div> -->
								</div>
								<div class="price-box">
									<div class="right-text">爱心积分{{item.integral}}</div>
									<div class="right-number">抵{{item.discount}}元</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</VantList>
			<div class="sort-container-body" v-if="active == 1">
				<!-- 商品导航 -->
				<div class="container-nav" ref="nav">
					<van-sidebar v-model="activeKey"><van-sidebar-item v-for="item in sidebarList" :title="item.title"
							@click="onSort(item.id)" /></van-sidebar>
				</div>
				<!-- 商品列表 -->
				<div class="container-goods">
					<div class="goods-list">
						<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty"
							@onLoad="onLoad">
							<div class="goods-items" v-for="item in companyList"
								@click="toDetail(item.id)">
								<div class="goods-items-thumb"><img :src="item.thumb" alt="" /></div>
								<div class="goods-items-detail">
									<div class="goods-items-title">{{ item.title }}</div>
									<div class="goods-items-price-box">
										<div class="right-text">爱心积分{{item.integral}}</div>
										<div class="right-number">抵{{item.discount}}元</div>
									</div>
									<div class="goods-items-attribute">
										<div class="goods-items-price">
											<div class="items-price-title">
												<span>￥</span>{{item.price}}
											</div>
											<!-- <div class="items-price-text">
												￥{{item.price}}{{parseInt(item.price*100 - item.discount*100)/parseInt(100).toFixed(2)}}
											</div> -->
										</div>
										<div class="goods-items-botton" @click.stop="addGoods(item)"
											v-if="item.value == 0 && item.stock != 0">加入购物车</div>
										<div class="goods-items-botton color-grey"
											v-if="item.value == 0 && item.stock == 0">库存不足</div>
										<van-stepper v-if="item.value != 0" v-model="item.value" min="1"
											@stepper-active-color="color" theme="round" button-size="22" disable-input
											@plus="onPlus(item)" @overlimit="onOverlimit" @minus="onMinus(item)" />

									</div>
								</div>
							</div>
						</VantList>
					</div>
				</div>
				<div class="container-footer">
					<div class="container-footer-left" @click="toShoppList">
						<van-badge :content="length"><img src="../../assets/img/shop.png" alt="" /></van-badge>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WTab from '@/components/Tab';
	import VantList from '@/components/VantList';
	import LoadingPage from '@/components/LoadingPage';
	import Commodity from '@/api/commodity';
	import VantVendor from '@/vendor/vant';
	import Config from '@/vendor/config';
	import Event from '@/store/event';
	export default {
		name: 'Commodity',
		data() {
			return {
				active: 0,
				navList: [{
					id: 1,
					title: '推荐商品'
				}, {
					id: 2,
					title: '商品分类'
				}],
				sidebarList: [],
				activeKey: 0,
				value: 0,
				color: '#3377FF',
				page: 1,
				limit: 10,
				title: '',
				classify_id: '',
				type: '',
				loading: true,
				finished: false,
				isEmpty: false,
				loadingPage: false,
				companyList: [],
				length: 0
			};
		},
		computed: {
			shopping() {
				return this.$store.state.shopping.shopping
			},
			userInfo() {
				return this.$store.state.user.userInfo;
			}
		},
		created() {
			document.title = '义警助力乡村振兴';
			
			this.$store.commit('keep/setKeep', 'Commodity');
			Commodity.productClassifyIndex().then(
				result => {
					this.sidebarList = result.data;
					this.onLoad();
				},
				error => {
					VantVendor.Toast.fail(error.msg);
				}
			);
			this.getShopp()
			wx.onMenuShareTimeline({
				title: '义警商城' + '-' + document.title,
				link: window.Location.href,
				imgUrl: Config.shareLogoUrl,
				success: () => {
					Vant.Toast.success('分享成功');
				}
			});

			wx.onMenuShareAppMessage({
				title: '义警商城' + '-' + document.title,
				link: window.Location.href,
				imgUrl: Config.shareLogoUrl,
				success: () => {
					Vant.Toast.success('分享成功');
				}
			});
		},
		methods: {
			getId(id) {
				return this.$store.state.shopping.shopping[id]
			},
			getShopp() {
				Commodity.productCartIndex()
					.then(result => {
						let list = {}
						result.data.list.forEach(item => {
							item.products.forEach(vo => {
								list[vo.product_id] = vo.num
							})
						})
						this.$store.commit('shopping/setShopping', list);
						this.shoppLength()
					}, error => {
						VantVendor.Toast.fail(error.msg)
					});
			},
			shoppLength() {
				return this.length = Object.keys(this.$store.state.shopping.shopping).length;
			},
			toSearch() {
				this.$router.push({
					name: 'CommoditySearch',
				});
			},
			toDetail(id){
				this.$router.push({
					name: 'CommodityDetail',
					params: { id: id }
				});
			},
			// 商品栏目
			onTab(index) {
				this.active = index;
				this.type = this.navList[index].id;
				this.page = 1;
				this.loading = true;
				this.finished = false;
				this.isEmpty = false;
				this.companyList = [];
				this.classify_id = '';
				this.onLoad();
			},
			// 商品列表
			onLoad() {
				this.loading = true;
				if (this.classify_id == '' && this.active == 1) {
					this.classify_id = this.sidebarList[0].id
				}
				let params = {
					page: this.page,
					limit: this.limit,
					title: this.title,
					classify_id: this.classify_id,
					type: this.type
				};
				Commodity.productIndex(params)
					.then(result => {
						this.page++;
						result.data.list.forEach((item, key) => {
							item.value = 0;
						});
						this.companyList = this.companyList.concat(result.data.list);

						this.loading = false;
						this.loadingPage = false;

						if (this.companyList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.companyList.length >= result.data.total) {
							this.finished = true;
						}
						let list = this.companyList
						list.forEach(item => {
							item.value = this.getId(item.id)
							if (this.getId(item.id) == undefined) {
								item.value = 0
							}
						})
						this.companyList = list
						this.shoppLength()
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 加入购物车
			addGoods(item) {
				if (item.stock == 0) {
					return VantVendor.Toast('商品库存不足')
				}
				if (item.value == 0) {
					item.value = 1
				}
				let params = {
					product_id: item.id,
					num: item.value,
				}
				Commodity.productCartCreate(params)
					.then(result => {
						VantVendor.Toast.success('加入购物车成功')
						this.getShopp()
					}, error => {
						item.value = 0
						VantVendor.Toast.fail(error.msg);
					})
			},
			// 商品数量++
			onPlus(item) {
				event.stopPropagation();
				let params = {
					product_id: item.id,
				}
				Commodity.productCartIncNum(params)
					.then(result => {
						console.log('添加购物车成功');
					}, error => {
						item.value--;
						VantVendor.Toast.fail(error.msg);
					})
			},
			// 商品数量--
			onMinus(item) {
				event.stopPropagation();
				let params = {
					product_id: item.id,
				}
				Commodity.productCartDecNum(params)
					.then(result => {
						console.log('减少购物车成功');
					}, error => {
						item.value++;
						VantVendor.Toast.fail(error.msg);
					})
			},
			onSort(id) {
				this.classify_id = id;
				this.page = 1;
				this.loading = true;
				this.finished = false;
				this.isEmpty = false;
				this.companyList = [];
				this.onLoad();
			},
			toShoppList() {
				this.$store.commit('shopping/setShopping', this.list);
				this.$router.push({
					name: 'CommodityList'
				})
			},
			onOverlimit() {
				event.stopPropagation();
			},
		},
		activated() {
			this.$store.commit('keep/removeKeep', 'CommoditySearch');
			if (this.$store.state.shopping.shopping.length == '') {
				this.companyList.forEach(item => {
					item.value = 0
				})
			}
			this.companyList.forEach(item => {
				item.value = this.getId(item.id)
				if (this.getId(item.id) == undefined) {
					item.value = 0
				}
			})
			this.shoppLength()
		},
		components: {
			WTab,
			VantList,
			LoadingPage
		}
	};
</script>

<style scoped lang="less">
	.Commodity {
		min-height: 100vh;
		background: #F2F2F2;
		box-sizing: border-box;
		position: relative;

		.header-top {
			position: absolute;
			top: 60px;
			right: 0;
			padding: 3px 10px;
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			border-radius: 14.5px 0px 0px 14.5px;
			box-sizing: border-box;
		}
		.header-top-list {
			position: absolute;
			top: 95px;
			right: 0;
			padding: 3px 10px;
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			border-radius: 14.5px 0px 0px 14.5px;
			box-sizing: border-box;
		}
		.commodity-top {
			width: 100%;
			height: 200px;
			margin-bottom: 10px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.container {
			height: 100%;

			.container-top {
				background: #f2f2f2;
				border-radius: 15px 15px 0px 0px;
				width: 100%;
				position: relative;
				z-index: 10;
				margin-top: -25px;

				.head-title {
					padding: 10px 10px 0;
					background: #f2f2f2;
					border-radius: 15px 15px 0px 0px;
				}
			}

			.container-top-active {
				background: #f2f2f2;
				border-radius: 15px 15px 0px 0px;
				width: 100%;
				position: relative;
				z-index: 10;
				margin-bottom: 5px;

				.head-title {
					padding: 10px 10px 0;
					background: #f2f2f2;
					border-radius: 15px 15px 0px 0px;
				}
			}

			.container-body {
				padding: 10px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				background: #f2f2f2;
				padding-bottom: 0;

				.item-box {
					width: 172px;
					background: #fff;
					box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
					border-radius: 8px;
					overflow: hidden;
					margin-bottom: 10px;

					.item-box-picture {
						width: 100%;
						height: 170px;

						img {
							width: 100%;
							height: 100%;
						}
					}
					.item-box-bottom{
						padding: 10px;
						box-sizing: border-box;
						.item-box-text {
							min-height: 44px;
							font-size: 14px;
							line-height: 22px;
							color: #000;
							box-sizing: border-box;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}
						
						.item-box-price {
							margin-top: 4px;
							box-sizing: border-box;
						
							.price-left {
								display: flex;
								align-items: center;
						
								.price-title {
									font-size: 16px;
									color: #f43e3d;
						
									span {
										font-size: 12px;
									}
								}
						
								.price-text {
									padding-left: 4px;
									font-size: 12px;
									line-height: 22px;
									text-decoration-line: line-through;
									color: #909399;
								}
							}
						
							.price-box {
								display: flex;
								align-items: center;
								margin-top: 4px;
								box-sizing: border-box;
						
								.right-text {
									font-size: 10px;
									line-height: 16px;
									color: #E95749;
									padding: 0 3px 0 3px;
									border: 1px solid #E95749;
									border-right: 1px dashed #E95749;
									box-sizing: border-box;
									background: rgba(233, 87, 73, .1);
									border-top-left-radius: 2px;
									border-bottom-left-radius: 2px;
								}
						
								.right-number {
									padding: 0 3px;
									font-size: 10px;
									line-height: 16px;
									border: 1px solid #E95749;
									border-left: 0;
									color: #E95749;
									box-sizing: border-box;
									border-top-right-radius: 2px;
									border-bottom-right-radius: 2px;
								}
							}
						}
					}
				}
			}

			.sort-container-body {
				display: flex;
				height: calc(100vh - 46px);
				overflow: hidden;
				margin: 0 10px;
				box-sizing: border-box;
				background-color: #fff;
				border-radius: 8px;
				box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);

				.container-nav {
					width: 90px;
					min-width: 90px;
					height: calc(100vh - 16px);
					background: #F5F6F8;

					.van-sidebar {
						width: 100%;

						.van-sidebar-item {
							text-align: center;
							padding: 12px;
							position: relative;
						}

						.van-sidebar-item--select {
							font-weight: bold;
							background-color: #fff;
							color: #F43E3D;

							&:before {
								content: '';
								display: block;
								width: 56px;
								height: 7px;
								background-color: rgba(51, 119, 255, .2);
								position: absolute;
								top: 30px;
								left: 17px;
							}
						}
					}
				}

				.container-goods {
					width: 286px;
					height: calc(100vh - 16px);

					.filter {
						position: relative;

						&::after {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							height: 100%;
							width: 1px;
							background-color: #f5f6f8;
						}
					}

					.goods-list {
						height: calc(100% - 30px);
						overflow: auto;
						background: #fff;
						border-radius: 8px;
						box-sizing: border-box;

						.goods-items:first-child {
							padding-top: 10px;
						}

						.goods-items {
							padding: 15px 10px;
							display: flex;
							position: relative;
							box-sizing: border-box;

							&::after {
								position: absolute;
								box-sizing: border-box;
								content: ' ';
								pointer-events: none;
								right: 10px;
								left: 10px;
								bottom: 0;
								border-bottom: 1px solid #F2F2F2;
								-webkit-transform: scaleY(0.5);
								transform: scaleY(0.5);
							}

							.goods-items-thumb {
								width: 80px;
								height: 80px;
								min-width: 80px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									border-radius: 6px;
								}
							}

							.goods-items-detail {
								flex: 1;
								padding-left: 10px;
								display: flex;
								flex-direction: column;
								justify-content: space-between;

								.goods-items-title {
									width: 155px;
									min-width: 155px;
									font-size: 14px;
									line-height: 22px;
									color: #000;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.goods-items-price-box {
									display: flex;
									align-items: center;
									box-sizing: border-box;

									.right-text {
										font-size: 10px;
										line-height: 16px;
										color: #E95749;
										padding: 0 3px 0 3px;
										border: 1px solid #E95749;
										border-right: 1px dashed #E95749;
										box-sizing: border-box;
										background: rgba(233, 87, 73, .1);
										border-top-left-radius: 2px;
										border-bottom-left-radius: 2px;
									}

									.right-number {
										padding: 0 3px;
										font-size: 10px;
										line-height: 16px;
										border: 1px solid #E95749;
										border-left: 0;
										color: #E95749;
										box-sizing: border-box;
										border-top-right-radius: 2px;
										border-bottom-right-radius: 2px;
									}
								}

								.goods-items-attribute {
									display: flex;
									justify-content: space-between;
									align-items: center;
									position: relative;

									.goods-items-price {
										.items-price-title {
											font-size: 18px;
											color: #f9615d;

											span {
												font-size: 12px;
											}
										}

										.items-price-text {
											font-size: 14px;
											line-height: 16px;
											text-decoration-line: line-through;
											color: #909399;
										}
									}

									.goods-items-units {
										display: flex;
										align-items: center;

										.items-minus {
											width: 22px;
											height: 22px;
											min-width: 22px;
											border-radius: 50%;
											border: 1px solid #3377ff;
											box-sizing: border-box;
											text-align: center;
											line-height: 22px;
										}

										.items-plus {}

										.items-num {
											min-width: 20px;
											margin: 0 2px;
											font-size: 16px;
											color: #222;
											padding-top: 4px;
											text-align: center;
										}
									}

									.goods-items-botton {
										font-size: 12px;
										line-height: 22px;
										color: #FFF;
										padding: 1px 6px;
										background: #3377FF;
										border-radius: 100px;
										box-sizing: border-box;
									}

									.color-grey {
										background: #AAA;
									}
								}

								/deep/.van-stepper--round .van-stepper__minus {
									color: #3377ff;
									background-color: #fff;
									border: 1px solid #3377ff;
									opacity: 1;
								}

								/deep/.van-stepper--round .van-stepper__plus {
									background: #3377ff;
								}
							}
						}
					}
				}

				.container-footer {
					position: fixed;
					left: 0;
					bottom: 0;

					.container-footer-left {
						width: 60px;
						height: 60px;
						margin-left: 10px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 50%;
						}

						/deep/.van-badge--fixed {
							position: absolute;
							top: 16px;
							right: 13px;
						}
					}
				}
			}
		}
	}
</style>